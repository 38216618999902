import { useHistory } from "react-router-dom";
import { useHostAdmin } from "../../../context/host-admin-context";
import { useMessaging } from "../../../context/messaging/messaging-context";
import TranslationService from "../../../services/translation-service";
import { MenuWrapper } from "./menu-styles";

const Menu = () => {
  const history = useHistory();
  const { hideSeekerConversation } = useHostAdmin();
  const { seeker } = useMessaging();

  const hideConversation = async () => {
    if (
      window.confirm(
        TranslationService.getTranslation("hide-conversation-confirmation")
      )
    ) {
      await hideSeekerConversation(seeker.uuid);
      history.goBack();
    }
  };

  const menuOptions = [
    { translation: "hide-conversation", onClick: hideConversation },
  ];
  return (
    <MenuWrapper>
      <ul>
        {menuOptions.map(({ onClick, translation }) => (
          <li key={translation} onClick={onClick}>
            <p>{TranslationService.getTranslation(translation)}</p>
          </li>
        ))}
      </ul>
    </MenuWrapper>
  );
};

export default Menu;
