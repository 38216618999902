import styled from 'styled-components'

export const TextArea = styled.textarea`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  resize: none;
  height: 42px;
  font-family: inherit;
`;
