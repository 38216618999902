import styled, {css} from "styled-components";

export const MessageWrapper = styled("div")`
  display: flex;
  color: #1f356e;
  ${props => props.isSeeker && css`
    justify-content: start;
  `};
  ${props => !props.isSeeker && css`
    justify-content: end;
  `};
`;

export const MessageBubble = styled("div")`
  position: relative;
  padding: 10px;
  width: 200px;
  text-align: left;
  border-radius: 10px;
  ${props => props.isSeeker && css`
    margin-left: 0;
    margin-bottom: 10px;
    background-color: #A8DDFD;
    border: 1px solid #97C6E3;
  `};
  ${props => !props.isSeeker && css`
    margin-bottom: 10px;
    background-color: #f8e896;
    border: 1px solid #dfd087;
  `};
  /*:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    ${props => props.isSeeker && css`
      border-top: 17px solid #97C6E3;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      top: -1px;
      left: -17px;
  `};
    ${props => !props.isSeeker && css`
      border-bottom: 17px solid #dfd087;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      bottom: -1px;
      right: -17px;
  `};
  }
  :after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    ${props => props.isSeeker && css`
      border-top: 15px solid #A8DDFD;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      top: 0;
      left: -15px
  `};
    ${props => !props.isSeeker && css`
      border-bottom: 15px solid #f8e896;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      bottom: 0;
      right: -15px;
  `};
  }*/
  transition: transform .2s;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export const MessageContent = styled("div")`
  padding: 0 0 1em 0;
  margin: 0;
`;

export const Timestamp = styled("div")`
  position: absolute;
  font-size: .7em;
  font-weight: 300;
  bottom: 5px;
  right: 5px;
`;