import React, { useState, useEffect } from "react";
//import { useAuth } from "../../context/ProvideAuth";
//import { useTranslation } from "react-i18next";
import { Link, withRouter, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TranslationService from "../../../../services/translation-service";
import uniteLifeLogo from "../../../../media/logo-ul-black.png";
import uniteLifeLogoDark from "../../../../media/logo-ul-white.png";
import umidTVLogo from "../../../../media/logo-umid-color.png";
import umidTVLogoDark from "../../../../media/logo-umid-white.png";
import masihiyLogo from "../../../../media/logo-masihiy-color.png";
import masihiyLogoDark from "../../../../media/logo-masihiy-white.png";

import { useHostAdmin } from "../../../../context/host-admin-context";
import getURLParams from "../../../../utilities/get-url-params";
import {
  faGripHorizontal,
  faUsers,
  faUserAlt,
  faUserCheck,
  faUserSlash,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
//import logo from "../../media/logo_color.png";
import "./sidebar.scss";


const Sidebar = ({ location,logo}) => {
  const { pathname } = location;
  const { host, logout } = useHostAdmin();
  const history = useHistory();
  //let auth = useAuth();
  //const { t } = useTranslation();

  const links = [
    {
      name: TranslationService.getTranslation("dashboard"),
      path: "dashboard",
      icon: faGripHorizontal,
    },
    {
      name: TranslationService.getTranslation("hq-unverified"),
      path: "uncontacted",
      icon: faUserSlash,
    },
    {
      name: TranslationService.getTranslation("hq-uncontacted"),
      path: "already_connected",
      icon: faUsers,
    },
    {
      name: TranslationService.getTranslation("hq-connected"),
      path: "confirmed_connected",
      icon: faUserCheck,
    },
    {
      name: TranslationService.getTranslation("profile"),
      path: "profile",
      icon: faUserAlt,
    },
    {
      name: TranslationService.getTranslation("help"),
      path: "help",
      icon: faQuestionCircle,
    },
  ];

  const renderLinks = (arrayOfLinks) => {
    if(!host) return;
    return arrayOfLinks.map(({ name, path, icon }, i) => {
      let active =
        path.length > 1 ? pathname.includes(path) : pathname === path;

      const fullPath = `/${path}`
      return (
        <Link key={i} to={fullPath} id={`page-link-${path}`}>
          <li className={`page-link-item btn ${active ? "btn-primary" : ""}`}>
            <FontAwesomeIcon icon={icon} />
            <p to={fullPath} className="text-sm-md bold page-link">
              {name}
            </p>
          </li>
        </Link>
      );
    });
  };

  const renderLogo = (host)=>{
   if(!host) return;
   let headerLogo = null;
   if (host?.country === "az") {
      headerLogo = isDark  ? umidTVLogoDark : umidTVLogo;
    } else if(host?.country ==="uz"){
      headerLogo = isDark  ? masihiyLogoDark : masihiyLogo;
    } else if (host?.country !== "cn") {
      headerLogo = isDark ? uniteLifeLogoDark :  uniteLifeLogo;
    }
  if(!headerLogo) return;
  return(
    <img
    src={headerLogo}
    alt="logo"
    className="logo-home"
    onClick={() => history.push('/dashboard')}/>
    )
  }

  const [isActive, setActive] = useState(false);
  const [isDark, setIsDark] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    document
      .getElementById("root")
      .setAttribute("data-theme", currentTheme ?? "light");

    if (currentTheme === "dark") {
      setIsDark(true);
    }
  }, []);

  function switchTheme(e) {
    if (e.target.checked) {
      document.getElementById("root").setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "dark");
      setIsDark(true);
    } else {
      document.getElementById("root").setAttribute("data-theme", "light");
      localStorage.setItem("theme", "light");
      setIsDark(false);
    }
  }

  function handleLogOut() {
    logout();
  }



  return (
    <nav className="sidebar">
      <div className="sidebar-wrapper">
        <div className="logo">
        {renderLogo(host)}
        </div>
        <div className="page-links">
          <div
            onClick={handleToggle}
            className={isActive ? "menu-icon active" : "menu-icon"}
          >
            <div className="menu-icon__bar menu-icon__bar--1"></div>
            <div className="menu-icon__bar menu-icon__bar--2"></div>
            <div className="menu-icon__bar menu-icon__bar--3"></div>
          </div>
          <ul className={isActive ? "active" : null}>
            {renderLinks(links)}

            <div className="theme-switch-wrapper">
              <span>{TranslationService.translations["dark-mode"]}</span>
              <label className="theme-switch" htmlFor="checkbox">
                <input
                  onChange={switchTheme}
                  checked={isDark}
                  type="checkbox"
                  id="checkbox"
                  aria-label="Switch visual theme"
                />
                <div className="slider round"/>
              </label>
            </div>
            <div className="logout-link">
              <Link to={"/"}>
                <button onClick={handleLogOut}>Logout</button>
              </Link>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Sidebar);
