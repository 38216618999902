import React from "react";
import TranslationService from "../../services/translation-service";

const WithSeekerProfileNotFound = (props) => {
  return props.seeker ? (
    props.component
  ) : (
    <div className="seeker-profile">
      <div className="seeker-not-found">
        <p>{TranslationService.translations["seeker-already-connected"]}</p>
      </div>
    </div>
  );
};

export default WithSeekerProfileNotFound;
