import styled from "styled-components";

export const Main = styled("div")`
  font-family: sans-serif;
  background: var(--ui-gray-dark);
  height: 100vh;
`;

export const DropDownContainer = styled("div")`
  position: relative;
  display: inline-block;
  margin: 0 0.25em;
`;

export const DropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--text-default);
  background: var(--ui-gray-lighter);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--ui-gray-lighter);

  &:hover {
    cursor: pointer;
  }
  border-radius: var(--border-radius-md);
`;

export const DropDownListContainer = styled("div")``;

export const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  background: var(--ui-gray-dark);
  border: 1px solid var(--ui-gray-lighter);
  box-sizing: border-box;
  color: var(--text-default);
  font-size: 1.3rem;
  font-weight: 500;
  position: absolute;
  transform: translate(0, -15px);
  width: 100%;
  z-index: 100;
  border-radius: var(--border-radius-md);
`;

export const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  padding: 0.8em 1em;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  border-radius: var(--border-radius-md);
`;
