import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import Sidebar from "./sidebar/sidebar";
import Footer from "./footer/footer";
import "./layout.scss";

const Layout = ({ children }) => {
    const location = useLocation();
    const omitSidebar = [
      '/',
      '/reset-password',
    ]
  return (
    <div className="layout">
        {!omitSidebar.includes(location.pathname) ? (<Sidebar />) : (<></>)}
      {children}
      <Footer />
    </div>
  );
};

export default withRouter(Layout);
