import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  faChevronLeft,
  faComment,
  faUser,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./seeker-profile.scss";
import { useMessaging } from "../../context/messaging/messaging-context";
import SeekerProfileInfo from "../../components/seeker-profile-info";
import WithSeekerProfileNotFound from "../../components/with-seeker-profile-not-found/with-seeker-profile-not-found";
import Spinner from "../../components/spinner";
import { Card, Message, Button } from "../../components/v2";
import TranslationService from "../../services/translation-service";
import { TextArea } from "../../components/v2/textarea/textarea-styles";
import Menu from "../../components/v2/menu/menu";

const SeekerProfile = () => {
  const [activeTab, setActiveTab] = useState("chat");
  const [showMenu, setShowMenu] = useState(false);
  const { seeker, loading, conversation, sendMessage } = useMessaging();
  const [newMessage, setNewMessage] = useState("");

  const sendNewMessage = () => {
    if (newMessage) {
      setNewMessage("");
      sendMessage(newMessage);
    }
  };
  const calcTextAreaHeight = (e, minHeight = 30, maxHeight = 100) => {
    e.target.style.height = `${minHeight}px`;
    if (
      e.target.scrollHeight >= minHeight &&
      e.target.scrollHeight < maxHeight
    ) {
      e.target.style.height = `${e.target.scrollHeight}px`;
    } else if (e.target.scrollHeight > maxHeight) {
      e.target.style.height = `${maxHeight}px`;
    }
  };
  return !loading ? (
    WithSeekerProfileNotFound({
      seeker,
      component: (
        <>
          <div className={"page"}>
            <section className="page__body">
              <section className="page__section grid--1-2">
                <section className="page__subsection">
                  <Card className="">
                    <Card.Heading>
                      <Card.Title>
                        {seeker?.first_name} {seeker?.last_name}
                      </Card.Title>
                    </Card.Heading>
                    <Card.Body>
                      <SeekerProfileInfo isMobileActive={true} />
                    </Card.Body>
                  </Card>
                </section>
                <section className="page__subsection">
                  <Card className="">
                    <Card.Heading>
                      <Card.Title>
                        {TranslationService.translations["chat-title"]}
                      </Card.Title>
                      <Card.MenuIconWrapper
                        active={showMenu}
                        onClick={() => {
                          setShowMenu(!showMenu);
                        }}
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </Card.MenuIconWrapper>
                      {showMenu && <Menu />}
                    </Card.Heading>
                    <Card.Body>
                      <div className={"messages-wrapper"}>
                        <div className={"messages-container"}>
                          {
                            conversation && conversation.messages.map((messageData, index) => {
                              return (
                                <div key={`message-${index}`}>
                                  <Message message={messageData} contactMethod={messageData.receiver_channel} />
                                </div>
                              );
                            })
                          }
                        </div>
                        <div className="message-input">
                          <TextArea
                            autoFocus
                            onChange={(e) => {
                              calcTextAreaHeight(e);
                              setNewMessage(e.target.value);
                            }}
                            value={newMessage}
                          />
                          <Button
                            text={TranslationService.translations["chat-send"]}
                            onClick={sendNewMessage}
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </section>
              </section>
            </section>
          </div>
          {/*<div className="seeker-profile">
              <div className="card">
                <div className="seeker-profile-header">
                  <FontAwesomeIcon
                      icon={faChevronLeft}
                      size="lg"
                      className="icon"
                      onClick={history.goBack}
                  />
                  <h2>
                    {seeker?.first_name} {seeker?.last_name}
                  </h2>

                  <FontAwesomeIcon
                      icon={faUser}
                      size="lg"
                      className={"toggle-icon"}
                      color={activeTab === "info" ? "#FDA533" : "#FFFFFF"}
                      onClick={() => setActiveTab("info")}
                  />
                  <FontAwesomeIcon
                      icon={faComment}
                      size="lg"
                      className={"toggle-icon"}
                      color={activeTab === "chat" ? "#FDA533" : "#FFFFFF"}
                      onClick={() => setActiveTab("chat")}
                  />
                </div>

                <SeekerProfileInfo isMobileActive={activeTab === "info"} />
                <MessageContainer isMobileActive={activeTab === "chat"} />
              </div>
            </div> */}
        </>
      ),
    })
  ) : (
    <Spinner text={"Loading.."} />
  );
};

export default withRouter(SeekerProfile);
