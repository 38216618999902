import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./host-profile.scss";
import { useForm } from "react-hook-form";
import { useHostAdmin } from "../../context/host-admin-context";
import Spinner from "../../components/spinner";
import TranslationService from "../../services/translation-service";
import { useNotifications } from "../../context/notification-context";
import { countries } from "../../utilities/countries";
import tosPDF from "../../media/terms-of-service.pdf";

import {
  PageHeaderBar,
} from "../../components/v2";

const HostProfile = () => {
  const { host, loading, seekers, updateHostProfile, loadHostData } =
    useHostAdmin();
  const breadcrumbPathName = TranslationService.getTranslation("profile");

  const formLabel = {
    name: TranslationService.getTranslation("name"),
    firstName: TranslationService.getTranslation("firstName"),
    lastName: TranslationService.getTranslation("lastName"),
    churchName: TranslationService.getTranslation("churchName"),
    phone: TranslationService.getTranslation("phone"),
    telegramId: TranslationService.getTranslation("telegramId"),
    preferredContact: TranslationService.getTranslation("preferredContact"),
    gender: TranslationService.getTranslation("gender"),
    female: TranslationService.getTranslation("female"),
    male: TranslationService.getTranslation("male"),
    country: TranslationService.getTranslation("country"),
    primaryLanguage: TranslationService.getTranslation("primaryLanguage"),
    isRequired: TranslationService.getTranslation("isRequired"),
    save: TranslationService.getTranslation("save"),
    azeri: TranslationService.getTranslation("azeri"),
    chinese: TranslationService.getTranslation("chinese"),
    english: TranslationService.getTranslation("english"),
    french: TranslationService.getTranslation("french"),
    hindi: TranslationService.getTranslation("hindi"),
    spanish: TranslationService.getTranslation("spanish"),
    russian: TranslationService.getTranslation("russian"),
    tamil: TranslationService.getTranslation("tamil"),
    uzbek: TranslationService.getTranslation("uzbek"),
    statementOfFaith: TranslationService.getTranslation("statementOfFaith"),
    faith1: TranslationService.getTranslation("faith1"),
    faith2: TranslationService.getTranslation("faith2"),
    faith3: TranslationService.getTranslation("faith3"),
    liabilityAgreement: TranslationService.getTranslation("liabilityAgreement"),
    iagree: TranslationService.getTranslation("iagree"),
    iagreeTo: TranslationService.getTranslation("iagreeTo"),
    download: TranslationService.getTranslation("download"),
    reviewLiabilityAgreement: TranslationService.getTranslation("review-liability-agreement"),
    updateContactInfo: TranslationService.getTranslation("update-contact-info"),
  };
  let { notifications, setNotifications, pushNotification } =
    useNotifications();

  // console.log({ host });

  const renderCountries = Object.entries(countries).map(([key, value]) => {
    return (
      <option key={key} value={key}>
        {value}
      </option>
    );
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    // console.log({ data });
    const profile = {
      // first_name: data.first_name,
      // last_name: data.last_name,
      name: data.name,
      gender: data.gender,
      country: data.country,
      primary_language: data.primaryLanguage,
      contact_method: data.contactMethod,
      agreements: {
        faith: data.faith,
        liability: data.liability
      }
    };

    const update = async () => {
      const updateStatus = await updateHostProfile(profile);
      if (updateStatus) {
        pushNotification(
          `${TranslationService.getTranslation("profileUpdated")}`
        );
      } else {
        pushNotification(
          `${TranslationService.getTranslation("profileNotUpdated")}`
        );
      }
      loadHostData();
    };
    update();
  };

  return !loading ? (
    <div className={"page"}>
      <section className="page__body">
        <section className="page__title">
          <PageHeaderBar breadcrumb={breadcrumbPathName} />
        </section>
        <section className="page__section">
          <section className="page__subsection">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <label htmlFor="name">{formLabel.name}</label>
                <input
                  {...register("name", { required: true })}
                  defaultValue={host.name || ""}
                  placeholder={formLabel.name}
                  className="input-text"
                />
                <span className={`${errors.name?.type ? "span-error" : ""}`}>
                  {errors.name?.type === "required" &&
                    `* ${formLabel.name} ${formLabel.isRequired}`}
                </span>
              </div>
              {/* <div className="field">
                <label htmlFor="first_name">{formLabel.firstName}</label>
                <input
                  {...register("first_name", { required: true })}
                  defaultValue={host.first_name || ""}
                  placeholder={formLabel.firstName}
                  className="input-text"
                />
                <span className={`${errors.first_name?.type ? "span-error" : ""}`}>
                  {errors.first_name?.type === "required" &&
                    `* ${formLabel.firstName} ${formLabel.isRequired}`}
                </span>
              </div>
              <div className="field">
                <label htmlFor="last_name">{formLabel.lastName}</label>
                <input
                  {...register("last_name")}
                  defaultValue={host.last_name || ""}
                  placeholder={formLabel.lastName}
                  className="input-text"
                />
                <span className={`${errors.last_name?.type ? "span-error" : ""}`}>
                  {errors.last_name?.type === "required" &&
                    `* ${formLabel.lastName} ${formLabel.isRequired}`}
                </span>
              </div> */}

              <div className="field">
                <label htmlFor="phone">{formLabel.phone}</label>
                <input
                  {...register("phone", { required: false })}
                  defaultValue={host.phone || ""}
                  placeholder={formLabel.phone}
                  className="fieldDisabled input-text"
                  disabled
                />
                <span>
                  {errors.phone?.type === "required" &&
                    `${formLabel.phone} ${formLabel.isRequired}`}
                </span>
              </div>

              <div className="field">
                <label htmlFor="telegramId">{formLabel.telegramId}</label>
                <input
                  {...register("telegramId", { required: false })}
                  defaultValue={host.telegram_id || ""}
                  placeholder={formLabel.telegramId}
                  className="input-text fieldDisabled"
                  disabled
                />
                <span>
                  {errors.telegramId?.type === "required" &&
                    `${formLabel.telegramId} ${formLabel.isRequired}`}
                </span>
              </div>

              <div className="field">
                <label htmlFor="contactMethod">{formLabel.preferredContact}</label>
                <select
                  {...register("contactMethod", { required: true })}
                  defaultValue={host.contact_method}
                >
                  <option value="sms">SMS</option>
                  <option value="whatsapp">WhatsApp</option>
                  <option value="email">Email</option>
                  <option value="telegram">Telegram</option>
                  <option value="facebook">Facebook</option>
                </select>
                <span className={`${errors.contact_method?.type ? "span-error" : ""}`}>
                  {errors.contact_method?.type === "required" &&
                    `${formLabel.preferredContact} ${formLabel.isRequired}`}
                </span>
              </div>

              <div className="field">
                <label htmlFor="gender">{formLabel.gender}</label>
                <select
                  {...register("gender", { required: true })}
                  defaultValue={host.gender}
                >
                  <option value="f">{formLabel.female}</option>
                  <option value="m">{formLabel.male}</option>
                </select>
                <span className={`${errors.gender?.type ? "span-error" : ""}`}>
                  {errors.gender?.type === "required" &&
                    `${formLabel.gender} ${formLabel.isRequired}`}
                </span>
              </div>

              <div className="field">
                <label htmlFor="country">{formLabel.country}</label>
                <select
                  {...register("country", { required: true })}
                  defaultValue={host.country}
                >
                  {renderCountries}
                </select>
                <span className={`${errors.country?.type ? "span-error" : ""}`}>
                  {errors.country?.type === "required" &&
                    `${formLabel.country} ${formLabel.isRequired}`}
                </span>
              </div>

              <div className="field">
                <label htmlFor="primaryLanguage">
                  {formLabel.primaryLanguage}
                </label>
                <select
                  {...register("primaryLanguage", { required: true })}
                  defaultValue={host.primary_language}
                >
                  <option value="az">{formLabel.azeri}</option>
                  <option value="zh">{formLabel.chinese}</option>
                  <option value="en">{formLabel.english}</option>
                  {/* <option value="fr">{formLabel.french}</option> */}
                  <option value="hi">{formLabel.hindi}</option>
                  {/* <option value="es">{formLabel.spanish}</option> */}
                  <option value="ru">{formLabel.russian}</option>
                  <option value="ta">{formLabel.tamil}</option>
                  <option value="uz">{formLabel.uzbek}</option>
                </select>
                <span
                  className={`${
                    errors.primaryLanguage?.type ? "span-error" : ""
                  }`}
                >
                  {errors.primaryLanguage?.type === "required" &&
                    `* ${formLabel.primaryLanguage} ${formLabel.isRequired}`}
                </span>
              </div>

              <div id="checkbox-agreements">
                <div className="field">
                  <label htmlFor="faith">{formLabel.statementOfFaith}</label>
                  <div className="field-checkbox">
                    <p>{formLabel.faith1}</p>
                    <p>{formLabel.faith2}</p>
                    <p>{formLabel.faith3}</p>
                    <div className="input-checkbox-wrap">
                      <input
                        {...register("faith", { required: true })}
                        type={"checkbox"}
                        className="input-checkbox"
                        value={""}
                        defaultChecked={host.agreements.faith}
                      />
                      <label htmlFor="faith" className="label-checkbox">
                        {formLabel.iagreeTo} {formLabel.statementOfFaith}
                      </label>
                    </div>
                  </div>
                  <span className={`${errors.faith?.type ? "span-error" : ""}`}>
                    {errors.faith?.type === "required" &&
                      `* ${formLabel.statementOfFaith} ${formLabel.isRequired}`}
                  </span>
                </div>

                <div className="field">
                  <label>{formLabel.liabilityAgreement}</label>
                  <div className="field-checkbox">
                    <a className="tos-download" href={tosPDF} download>{formLabel.reviewLiabilityAgreement}</a>
                    <div className="input-checkbox-wrap">
                      <input
                        {...register("liability", { required: true })}
                        type={"checkbox"}
                        className="input-checkbox"
                        value={""}
                        defaultChecked={host.agreements.liability}
                      />
                      <label htmlFor="liability" className="label-checkbox">
                        {formLabel.iagreeTo} {formLabel.liabilityAgreement}
                      </label>
                    </div>
                  </div>
                  <span
                    className={`${errors.liability?.type ? "span-error" : ""}`}
                  >
                    {errors.liability?.type === "required" &&
                      `* ${formLabel.liabilityAgreement} ${formLabel.isRequired}`}
                  </span>
                </div>
              </div>

              <span className="note">{formLabel.updateContactInfo}</span>
              <input
                className="btn-save"
                type="submit"
                value={formLabel.save}
              />
            </form>
          </section>
        </section>
      </section>
    </div>
  ) : (
    <Spinner text={"Loading.."} />
  );
};

export default withRouter(HostProfile);
