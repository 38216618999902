import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHostAdmin } from "../../context/host-admin-context";
import "./reset-password.scss";
import {
  Card,
  Input,
} from "../../components/v2";

const ResetPassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const { resetPassword } = useHostAdmin();

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const resetUserPassword = await resetPassword(email, oldPassword, newPassword);
    if (!resetUserPassword) {
        setErrors(['Invalid credentials']);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdatePassword(event);
    }
  };

  const handleOldPasswordChange = (event) => {
    setErrors([]);
    setOldPassword(event.target.value);
  };
  const handleNewPasswordChange = (event) => {
    setErrors([]);
    setNewPassword(event.target.value);
  };
  const handleEmailChange = (event) => {
    setErrors([]);
    setEmail(event.target.value);
  };

  return (
    <div className={"password-reset-wrapper page"}>
      <section className="page__body">
        <section className="page__section">
          <section className="page__subsection">
            <section className="page__subsection">
              <Card className="password-reset-card">
                <Card.Heading>
                    <h1>Connect Center - Reset Password</h1>
                </Card.Heading>
                <Card.Body className={"password-reset-box"}>
                  <form className={"password-reset"} onSubmit={(e) => handleUpdatePassword(e)}>
                    <label>Email:</label>
                    <Input
                      type={"text"}
                      onChange={handleEmailChange}
                      value={email}
                      onKeyPress={handleKeyPress}
                      className={"password-reset-box"}
                      placeholder="jane.smith@domain.com"
                      required
                    />
                    <label>Old Password:</label>
                    <Input
                      type={"password"}
                      autoFocus
                      onChange={handleOldPasswordChange}
                      value={oldPassword}
                      onKeyPress={handleKeyPress}
                      className={"password-reset-box"}
                      placeholder="**********"
                      required
                    />
                    <label>New Password:</label>
                    <Input
                      type={"password"}
                      onChange={handleNewPasswordChange}
                      value={newPassword}
                      onKeyPress={handleKeyPress}
                      className={"password-reset-box"}
                      placeholder="**********"
                      required
                    />
                    <button className="submit">
                      Submit
                    </button>
                    <ul className="error-message">
                      {errors.map((error, i) => (
                        <li key={`login-error${i}`}>{error}</li>
                      ))}
                    </ul>
                    <Link to={'/'}>
                      <span className="text-sm-md bold page-link">
                        Return to login
                      </span>
                    </Link>
                  </form>
                </Card.Body>
              </Card>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
};

export default ResetPassword;
