import React from "react";
import {MessageBubble, MessageWrapper, MessageContent, Timestamp} from "./message-styles";
import moment from "moment";

const Message = ({message, contactMethod}) => {
    if(!message) return null;
    const timestamp = message.created_at ? moment(message.created_at).format("MMM D, YYYY hh:mm A") : '';
    const sentBySeeker = message.direction === "seeker_to_host";
    return (
            <MessageWrapper isSeeker={sentBySeeker}>
                <MessageBubble isSeeker={sentBySeeker}>
                    <MessageContent isSeeker={sentBySeeker}>
                        {message.text}
                    </MessageContent>
                    <Timestamp isSeeker={sentBySeeker}>
                        {timestamp}
                    </Timestamp>
                </MessageBubble>
            </MessageWrapper>
        )
}
export default Message;