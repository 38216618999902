import styled from "styled-components";

export const MenuWrapper = styled.nav`
  background: var(--ui-gray-lighter);
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 5;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  li {
    padding: 10px;
    cursor: pointer;
  }
  li:hover {
    background: #c8c8c878;
  }
`
