import React, {useState, useEffect} from "react";
import Card from "../card/card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {useHostAdmin} from '../../../context/host-admin-context';
import getURLParams from "../../../utilities/get-url-params";
import TranslationService from "../../../services/translation-service";
const NotificationList = ({showHeader, headerText}) => {
    if (showHeader === undefined) {
        showHeader = true
    }
    const header = (
        <Card.Heading>
            <Card.Title>{headerText}</Card.Title>
        </Card.Heading>
    )
    const {unreadMessages} = useHostAdmin();
    const [unreadList, setUnreadList] = useState();
    useEffect(() => {
        let unreadMessageList;
        if (unreadMessages && unreadMessages.length) {
            unreadMessageList = unreadMessages.map((message,index) => {
                return (
                    <div className={"preview_message"} key={`unread-message-${index}`}>
                        <div>
                            <div>
                                {message.receiver_first_name} {message.receiver_last_name}
                            </div>
                            <div>
                                {message.text.length > 15 ? `${message.text.slice(0,15)}....` : message.text}
                            </div>
                        </div>
                        <div>
                            <Card.ViewLink to={`/seeker/${message.seeker_uuid}`} className="text-sm bold">
                                View
                                <Card.IconWrapper>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </Card.IconWrapper>
                            </Card.ViewLink>
                        </div>
                    </div>
                );
            });
        }
        else {
            unreadMessageList = (
                <div className={"preview_message"}>
                    <div>
                        <div>
                        </div>
                        <div>
                        {TranslationService.getTranslation("header-no-data")}
                        </div>
                    </div>
                </div>
            )
        }
        setUnreadList(unreadMessageList);

        //eslint-disable-next-line
    }, [unreadMessages]);

    return (
        <Card className="">
            {showHeader ? header : null}
            <Card.Body>
                {unreadList}
            </Card.Body>
        </Card>
    )
}
export default NotificationList;