import React from "react";
import "./spinner.scss";

export default function Spinner({ alt, text }) {
  let className = alt ? "spinner alt" : "spinner"
  return (
    <div className="spinner-wrapper">
      <div className={className} />
      <p>{text}</p>
    </div>
  )
}