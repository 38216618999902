import React from "react";
//import { useTranslation } from "react-i18next";

//import logo from "../../media/logo_white.png";
import "./footer.scss";

const Footer = () => {
  //const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer-content">
        <span className="footer-text">Powered By Unite Life</span>
        <div className="logo">
          {/*<img src={logo} alt="Unite Life Logo" />*/}
        </div>
      </div>
    </div>
  );
};

export default Footer;
