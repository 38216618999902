import React, { useState, useEffect } from "react";
import "./dashboard.scss";

import { withRouter } from "react-router-dom";
import { useHostAdmin } from "../../context/host-admin-context";
import { useImpactMetrics } from "../../hooks";
import Spinner from "../../components/spinner";
import {
  Card,
  NotificationList,
  Dropdown,
  PageHeaderBar,
} from "../../components/v2";
import TranslationService from "../../services/translation-service";

const Dashboard = () => {
  const timeFrameOptions = [
    { value: "thisMonth", label: TranslationService.getTranslation("this-month") },
    { value: "lastMonth", label: TranslationService.getTranslation("last-month") },
    { value: "allTime", label:  TranslationService.getTranslation("all-time") },
  ];

  const [timeFrame, setTimeFrame] = useState(timeFrameOptions[0]);
  const handleTimeFrameChange = (event) => {
    setTimeFrame(event);
  };
  const { host, loading } = useHostAdmin();
  const breadcrumbPathName = TranslationService.getTranslation("hello")+", "+ host?.name;
  const { setDateRange, impactMetrics } = useImpactMetrics();
  useEffect(() => {
    setDateRange(timeFrame.value);
    /* show correct translation by default when host loads */
    if(host && loading) setTimeFrame(timeFrameOptions[0]);
  }, [host, loading, timeFrame, setDateRange]);

  return !loading ? (
    <div className={"page"}>
      <section className="page__body">
        <section className="page__title">
          <PageHeaderBar breadcrumb={breadcrumbPathName} />
        </section>

        <section className="page__section grid--1-1">
          <section className="page__subsection">
            <section className="page__subsection">
              <Card className="">
                <Card.Heading>
                  <Card.Title>
                    {TranslationService.translations["impact-metrics-for"]}
                    <div className={"title-flex"}>
                      <Dropdown
                        options={timeFrameOptions}
                        value={timeFrame}
                        onChange={handleTimeFrameChange}
                      />
                    </div>
                  </Card.Title>
                </Card.Heading>
                <Card.Body>
                  <div className={"overview_numbers"}>
                    <div className={"overview_number_tile"}>
                      <h1>
                        {impactMetrics?.connected === undefined
                          ? "--"
                          : impactMetrics?.connected}
                      </h1>
                      <h4>{TranslationService.translations["stat-connected"]}</h4>
                    </div>
                    <div className={"overview_number_tile"}>
                      <h1>
                        {impactMetrics?.inPerson === undefined
                          ? "--"
                          : impactMetrics?.inPerson}
                      </h1>
                      <h4>{TranslationService.translations["stat-inpersons"]}</h4>
                    </div>
                    <div className={"overview_number_tile"}>
                      <h1>
                        {impactMetrics?.salvations === undefined
                          ? "--"
                          : impactMetrics?.salvations}
                      </h1>
                      <h4>{TranslationService.translations["stat-salvations"]}</h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </section>
          </section>
          <section className="page__subsection">
            <NotificationList headerText={TranslationService.getTranslation("header-new-messages")} />
          </section>
        </section>
      </section>
    </div>
  ) : (
    <Spinner text={"Loading.."} />
  );
};

export default withRouter(Dashboard);
