import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import { useHostAdmin } from "../../../context/host-admin-context";
import React, {useState} from "react";
import {PageHeaderBarWrapper, PageHeaderBarTitle,MessageIconWrapper, HasUnreadMessagesIcon, MessagesOverlay} from "./page-header-bar-styles";
import {NotificationList} from '../../v2'

const PageHeaderBar = ({breadcrumb, messages}) => {
    const {numberOfUnreadMessages} = useHostAdmin();
    const handleMouseOver = (event) => {
        setShowMessages(true);
    }
    const handleMouseOut = (event) => {
        setShowMessages(false);
    }
    const [showMessages,setShowMessages] = useState(false);

    return (
        <PageHeaderBarWrapper>
            <PageHeaderBarTitle>{breadcrumb}</PageHeaderBarTitle>
            <MessageIconWrapper id="header-message-icon">
                <HasUnreadMessagesIcon messageCount={numberOfUnreadMessages}>
                    <FontAwesomeIcon icon={faCircle} size={'xs'}/>
                </HasUnreadMessagesIcon>
                <span onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    <FontAwesomeIcon icon={faEnvelope} size={'2x'}/>
                    <MessagesOverlay showMessages={showMessages}>
                        <NotificationList/>
                    </MessagesOverlay>
                </span>
            </MessageIconWrapper>
        </PageHeaderBarWrapper>
    )
}
export default PageHeaderBar;