import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useHostAdmin } from "../context/host-admin-context";
import APIService from "../services/api-service";
const useImpactMetrics = () => {
  const { host, loading } = useHostAdmin();
  /**
   * @typedef {{ salvations: number, inPerson: number, connected: number}} ImpactMetrics
   * @type {[ImpactMetrics,(metrics:ImpactMetrics)=>void]}
   */

  /**
   * @typedef {"thisMonth"| "lastMonth"|"allTime"} DateRange
   * @type {[DateRange,(newRange:DateRange)=>void]}
   */
  const [dateRange, setDateRange] = useState();
  /**
   * @type {boolean}
   */
  const result = useQuery(["impact-metrics", dateRange, loading], async () => {
    if (!loading) {
      return APIService.getHostImpactMetrics(dateRange);
    }
  });
  useEffect(() => console.log(result.data), [result.data]);
  return {
    setDateRange,
    impactMetrics: result.data,
    loading: loading || result.loading,
  };
};
export default useImpactMetrics;
