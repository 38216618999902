import React, { useEffect, useContext } from "react";
import { Switch, Route, withRouter, Redirect, useHistory } from "react-router-dom";
import { Layout as V2Layout } from "./components/v2/";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Help from "./pages/help";
import HostProfile from "./pages/host-profile";
import SeekerProfile from "./pages/seeker-profile";
import { useHostAdmin } from "./context/host-admin-context";
import SeekerList from "./pages/seeker-list";
import ResetPassword from "./pages/reset-password";
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: false
    },
    attachTo: {
      on: 'auto-start'
    }
  },
  useModalOverlay: true
};

function TourStartButton() {
  const tour = useContext(ShepherdTourContext);

  return (
    <button id="tour-start-button" className="button dark" onClick={tour.start}>
      Start Tour
    </button>
  );
}

const Main = () => {
  const {
    host,
    connectWebsocket,
    loading,
    walkthroughSteps,
    updateHostProfile
  } = useHostAdmin();

  useEffect(() => {
    if (!host?.walkthrough && !loading) {
      document.querySelector('#tour-start-button').click();
      updateWalkthroughStatus();
    }
  }, [loading]);

  const updateWalkthroughStatus = async () => {
    await updateHostProfile({ walkthrough: true });
  };

  return (
    <V2Layout>
      <ShepherdTour steps={walkthroughSteps} tourOptions={tourOptions}>
        <TourStartButton />
      </ShepherdTour>
      <Switch>
        <Route path="/" exact>
          <Login connect={connectWebsocket} />
        </Route>
        <Route
          path="/seeker/:id"
          render={(props) => <SeekerProfile {...props} />}
        />
        <Route
          exact
          path="/"
          render={(props) => (
            <Redirect to={`/dashboard`} />
          )}
        />
        <Route
          exact
          path="/dashboard"
          render={(props) => (
            <Dashboard to={`/dashboard`} />
          )}
        />
        <Route
          exact
          path="/profile"
          render={(props) => (
            <HostProfile to={`/profile`} />
          )}
        />
        <Route
            exact
            path="/help"
            render={(props) => (
                <Help to={`/help`} />
            )}
        />
        <Route
          exact
          path="/reset-password"
          render={(props) => (
            <ResetPassword to={'/reset-password'} />
          )}
        />
        <Route
          exact
          path="/:filter"
          render={(props) => (
            <SeekerList {...props} filter={props.match.params.filter} />
          )}
        />
      </Switch>
    </V2Layout>
  );
};

export default withRouter(Main);
