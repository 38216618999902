import _translations from "../translations/translations";

export default class TranslationService {
  static languages = [
    {
      name: "english",
      code: "en",
      label: "English",
    },
    {
      name: "hindi",
      code: "hi",
      label: "हिन्दी",
    },
    {
      name: "tamil",
      code: "ta",
      label: "தமிழ்",
    },
    {
      name: "russian",
      code: "ru",
      label: "русский",
    },
    {
      name: "turkish",
      code: "tr",
      label: "Türk",
    },
    {
      name: "azeri",
      code: "az",
      label: "Azəri",
    },
    {
      name: "uzbek",
      code: "uz",
      label: "O'zbek",
    },
    {
      name: "chinese",
      code: "zh",
      label: "中国人",
    },
  ];

  static translations = _translations["en"];

  static getLanguageIndex(lang) {
    let lowerLang = "";
    if (lang) {
      lowerLang = lang.toLowerCase();
    } else {
      lowerLang = "en";
    }
    let result = this.languages.findIndex(
      (x) => x.code === lang || x.name === lowerLang
    );
    return result >= 0 ? result : 0;
  }

  static getLanguageCode(lang) {
    let lowerLang = "";
    if (lang) {
      lowerLang = lang.toLowerCase();
    } else {
      lowerLang = "en";
    } 
    let result = this.languages.find(
      (x) => x.code === lang || x.name === lowerLang
    );
    return result ? result.code : "en";
  }

  static loadTranslations(language) {
    let code = this.getLanguageCode(language);
    this.translations = _translations[code];
  }

  static getTranslation(key) {
    return this.translations[key] ?? _translations["en"][key];
  }
}
