import React from "react";
import "./help.scss";
import Spinner from "../../components/spinner";
import TranslationService from "../../services/translation-service";
import { withRouter } from "react-router-dom";
import { useHostAdmin } from "../../context/host-admin-context";
import { PageHeaderBar, Accordion } from "../../components/v2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Help = () => {
    const welcome = {
        heading: TranslationService.getTranslation("welcome-to-connect-center"),
        welcome1: TranslationService.getTranslation("welcome-1"),
        welcome2: TranslationService.getTranslation("welcome-2"),
        welcome3: TranslationService.getTranslation("welcome-3")
    };
    const hostBestPractices = [
        { text: TranslationService.getTranslation("host-best-practice-1") },
        { text: TranslationService.getTranslation("host-best-practice-2") },
        { text: TranslationService.getTranslation("host-best-practice-3") },
        { text: TranslationService.getTranslation("host-best-practice-4") },
        { text: TranslationService.getTranslation("host-best-practice-5") },
        { text: TranslationService.getTranslation("host-best-practice-6") },
        { text: TranslationService.getTranslation("host-best-practice-7") },
        { text: TranslationService.getTranslation("host-best-practice-8") },
        { text: TranslationService.getTranslation("host-best-practice-9") },
        { text: TranslationService.getTranslation("host-best-practice-10") },
    ];
    const faqs = [
        { label: TranslationService.getTranslation("faq-question-1"), body: TranslationService.getTranslation("faq-answer-1") },
        { label: TranslationService.getTranslation("faq-question-2"), body: TranslationService.getTranslation("faq-answer-2") },
        { label: TranslationService.getTranslation("faq-question-3"), body: TranslationService.getTranslation("faq-answer-3") },
        { label: TranslationService.getTranslation("faq-question-4"), body: TranslationService.getTranslation("faq-answer-4") },
        { label: TranslationService.getTranslation("faq-question-5"), body: TranslationService.getTranslation("faq-answer-5") },
        { label: TranslationService.getTranslation("faq-question-6"), body: TranslationService.getTranslation("faq-answer-6") },
        { label: TranslationService.getTranslation("faq-question-7"), body: TranslationService.getTranslation("faq-answer-7") },
        { label: TranslationService.getTranslation("faq-question-8"), body: TranslationService.getTranslation("faq-answer-8") },
        { label: TranslationService.getTranslation("faq-question-9"), body: TranslationService.getTranslation("faq-answer-9") },
        { label: TranslationService.getTranslation("faq-question-10"), body: TranslationService.getTranslation("faq-answer-10") },
    ];
    const faithStatement = {
        heading: TranslationService.getTranslation("statementOfFaith"),
        faith1: TranslationService.getTranslation("faith1"),
        faith2: TranslationService.getTranslation("faith2"),
        faith3: TranslationService.getTranslation("faith3")
    };

    const { loading } = useHostAdmin();
    const breadcrumbPathName = TranslationService.getTranslation("help");

    return !loading ? (
        <div className={"page help-page"}>
            <section className="page__body help-support">
                <section className="page__title">
                    <PageHeaderBar breadcrumb={breadcrumbPathName}/>
                </section>
                <section className="page__section tutorial-video">
                    <section className="page__subsection">
                        <iframe src="https://player.vimeo.com/video/715726479?h=4b4a762359&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    </section>
                </section>
                <section className="page__section">
                    <section className="page__subsection">
                        <h2>{welcome.heading}</h2>
                        <p>{welcome.welcome1}</p>
                        <p>{welcome.welcome2}</p>
                        <p>{welcome.welcome3}</p>
                    </section>
                </section>
                <section className="page__section host-best-practices">
                    <section className="page__subsection">
                        <h2>{ TranslationService.translations["host-best-practices"] }</h2>
                        <ul>
                            {hostBestPractices.map((suggestion) => {
                                return (
                                <li key={Math.random()}>
                                    <FontAwesomeIcon icon={faCheck} />
                                    {suggestion.text}
                                </li>
                                );
                            })}
                        </ul>
                    </section>
                </section>
                <section className="page__section ul-statement-of-faith">
                    <section className="page__subsection">
                        <h2>{faithStatement.heading}</h2>
                        <p>{faithStatement.faith1}</p>
                        <p>{faithStatement.faith2}</p>
                        <p>{faithStatement.faith3}</p>
                    </section>
                </section>
                <section className="page__section faq">
                    <section className="page__subsection">
                        <h2>{ TranslationService.translations["frequently-asked-questions"] }</h2>
                        <Accordion options={faqs} />
                    </section>
                </section>
            </section>
        </div>
    ) : (
        <Spinner text={"Loading.."} />
  );
}

export default withRouter(Help);