import { w3cwebsocket as W3CWebSocket } from "websocket";
import { io } from "socket.io-client";

class WebsocketService {
  static URL = process.env.REACT_APP_WEBSOCKET_URL;

  static client = null;
  static handlers = {};
  static socketID = null;
  static hostID = null;

  static pingTimeout = null;

  static initWebSocketClient(hostID, initialHandlers) {
    if (!this.socketID || hostID !== this.hostID) {
      this.handlers = { ...this.handlers, ...initialHandlers };
      this.handlers["__pong__"] = () => this.pong();
      this.handlers["init"] = ({ socketID }) => (this.socketID = socketID);
      this.hostID = hostID;

      this.client = io(this.URL, {
        reconnectionDelayMax: 10000,
      });
      console.log(this.client)

      // Send
      this.client.emit("init", { uuid: hostID });

      // Recieve
      this.client.on("message", (message) => {
        console.log('got the message')
        this.handleMessage(message);
      })

      this.client.on('disconnect', () => {
        console.log('disconnect')
      })

      // On error
      this.client.on("error", (error) => {
        console.error(`WebSocket error: ${error}`);
      })

    }
  }

  static setHandler(action, func) {
    this.handlers[action] = func;
  }

  static handleMessage(message) {
    let messageData = JSON.parse(message);

    if (this.handlers["message"]) {
      this.handlers["message"](messageData);
    }
  }

  static disconnectSocket() {
    console.log('disconnect')
    this.client.disconnect();
  }

}

export default WebsocketService;
