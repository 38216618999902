import React from "react";
import {
    ButtonStyled
} from './button-styles';

export default function Button({ text,onClick}) {

    return (
        <ButtonStyled onClick={onClick}>
            {text}
        </ButtonStyled>
    );
}