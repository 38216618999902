import actions from "./messaging-actions";

const MessagingReducer = (state, action) => {
  switch (action.type) {
    case actions.load:
      return {
        ...state,
        conversation: action.payload.conversation,
        seeker: action.payload.seeker,
        loading: false,
      };
    case actions.message:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: [...state.conversation.messages, action.payload]
        },
      };
    case actions.notDelivered:
      let { conversation } = state;
      conversation[conversation.length - 1].delivered = false;
      return {
        ...state,
        ...conversation
      };
    case actions.clear:
      return {
        ...state,
        seeker: null,
        conversation: {},
        loading: true,
      };
    default:
      throw new Error();
  }
};

export default MessagingReducer;
