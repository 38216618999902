import React, { useState } from "react";
import { Link} from "react-router-dom";
import { useHostAdmin } from "../../context/host-admin-context";
import TranslationService from "../../services/translation-service";
import "./login.scss";
import {
  Card,
  Input,
} from "../../components/v2";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const { login } = useHostAdmin();

  const loginHost = async (e) => {
    e.preventDefault();
    const loginUser = await login(email, password);
    if (!loginUser) {
      setErrors(['Invalid credentials']);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      loginHost(event);
    }
  };

  const handleEmailChange = (event) => {
    setErrors([]);
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setErrors([]);
    setPassword(event.target.value);
  };

  return (
    <div className={"login-wrapper page"}>
      <section className="page__body">
        <section className="page__section">
          <section className="page__subsection">
            <section className="page__subsection">
              <Card className="login-card">
                <Card.Heading>
              <h1>Connect Center - Login</h1>
              </Card.Heading>
                <Card.Body className={"login-box"}>
                  <form className={"login"} onSubmit={(e) => loginHost(e)}>
                    <label>Email:</label>
                    <Input
                      type={"email"}
                      name="email"
                      autoFocus
                      onChange={handleEmailChange}
                      value={email}
                      onKeyPress={handleKeyPress}
                      className={"login-box"}
                      placeholder="jane.smith@domain.com"
                      required
                    />
                    <label>Password:</label>
                    <Input
                      type={"password"}
                      name="password"
                      onChange={handlePasswordChange}
                      value={password}
                      onKeyPress={handleKeyPress}
                      className={"login-box"}
                      placeholder="**********"
                      required
                    />
                    <button className="submit" type="submit">
                      {TranslationService.translations["connect"]}
                    </button>
                    <ul className="error-message">
                      {errors.map((error, i) => (
                        <li key={`login-error${i}`}>{error}</li>
                      ))}
                    </ul>
                    <Link to={'/reset-password'}>
                      <span className="text-sm-md bold page-link">
                        Reset password
                      </span>
                    </Link>
                  </form>
                </Card.Body>
              </Card>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
};

export default Login;
