import { useInfiniteQuery, useQueryClient } from "react-query";
import APIService from "../services/api-service";

/**
 * @typedef {"uncontacted"| "already_connected"|"confirmed_connected"} ConnectionStatus
 * @type {[ConnectionStatus,(newType:ConnectionStatus)=>void]}
 */

const useSeekers = (connectionStatus) => {
  const queryClient = useQueryClient();

  const fetchSeekers = async ({ pageParam = 1 }) => {
    const data = await APIService.getHostSeekers(connectionStatus, pageParam);
    return data;
  }

  const seekersQuery = useInfiniteQuery(
    ['seekers', connectionStatus],
    fetchSeekers,
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  const fetchMoreSeekers = () => {
    if (!seekersQuery.isFetchingNextPage && seekersQuery.hasNextPage) {
      seekersQuery.fetchNextPage();
    }
  };

  const refetchSeekers = () => {
    queryClient.invalidateQueries(['seekers', connectionStatus]);
  };

  const formatSeekers = (pages) => {
    if (!pages) return [];
    return pages.flatMap(page => page.seekers.map(seeker => {
      const seekerUser = seeker.user;
      return {
        ...seeker,
        ...seekerUser
      }
    }));
  };  

  return {
    seekers: formatSeekers(seekersQuery.data?.pages) || [],
    fetchMoreSeekers,
    refetchSeekers,
    isLoading: seekersQuery.isLoading,
    isFetchingMore: seekersQuery.isFetchingNextPage,
    hasNextPage: seekersQuery.hasNextPage,
  };
};

export default useSeekers;
