import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./Main";
//import "./styles/_config.scss";
import "./styles/v2/global.scss";
import "./App.scss";
import "./_defaults.scss";
import HostAdminContext from "./context/host-admin-context";
import MessagingContext from "./context/messaging/messaging-context";
import NotificationContext from "./context/notification-context";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <NotificationContext>
          <HostAdminContext>
            <MessagingContext>
              <Main />
            </MessagingContext>
          </HostAdminContext>
        </NotificationContext>
      </Router>
    </QueryClientProvider>
  );
}
