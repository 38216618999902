import TranslationService from "../services/translation-service";

export default function getWalkthroughSteps() {
    return [
        {
            id: 'intro',
            buttons: [
            {
                classes: 'shepherd-button-primary',
                text: TranslationService.getTranslation("get-started"),
                type: 'next'
            }
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-welcome"),
            text: [TranslationService.getTranslation("walkthrough-welcome-body")],
        },
        {
            id: 'tabs',
            attachTo: {
                element: '.sidebar ul',
                on: window.innerWidth <= 768 ? 'top' : 'right-start'
            },
            buttons: [
            {
                classes: 'shepherd-button-primary next',
                text: '',
                type: 'next'
            }
            ],
            classes: 'walkthrough-step-tabs',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-tabs"),
            text: [TranslationService.getTranslation("walkthrough-tabs-body")],
            when: {
                show: () => {
                    if (window.innerWidth <= 768) {
                        const closedMenuButton = document.querySelector('.page-links .menu-icon:not(.active)');
                        closedMenuButton && closedMenuButton.click();
                    }
                },
            }
        },
        {
            id: 'dashboard',
            attachTo: {
                element: '.sidebar #page-link-dashboard',
                on: window.innerWidth <= 768 ? 'bottom' : 'right'
            },
            buttons: [
            {
                classes: 'shepherd-button-primary prev',
                text: '',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: '',
                type: 'next'
            }
            ],
            classes: 'arrow-left',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-dashboard"),
            text: [TranslationService.getTranslation("walkthrough-dashboard-body")],
            when: {
                show: () => {
                    const dashboardLink = document.querySelector('.sidebar #page-link-dashboard');
                    dashboardLink.click();
                },
            }
        },
        {
            id: 'unverified',
            attachTo: {
                element: '.sidebar #page-link-created',
                on: window.innerWidth <= 768 ? 'bottom' : 'right'
            },
            buttons: [
            {
                classes: 'shepherd-button-primary prev',
                text: '',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: '',
                type: 'next'
            }
            ],
            classes: 'arrow-left',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-unverified"),
            text: [TranslationService.getTranslation("walkthrough-unverified-body")],
            when: {
                show: () => {
                    const unverifiedLink = document.querySelector('.sidebar #page-link-created');
                    unverifiedLink.click();
                },
            }
        },
        {
            id: 'uncontacted',
            attachTo: {
                element: '.sidebar #page-link-verified',
                on: window.innerWidth <= 768 ? 'bottom' : 'right'
            },
            buttons: [
            {
                classes: 'shepherd-button-primary prev',
                text: '',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: '',
                type: 'next'
            }
            ],
            classes: 'arrow-left',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-uncontacted"),
            text: [TranslationService.getTranslation("walkthrough-uncontacted-body")],
            when: {
                show: () => {
                    const uncontactedLink = document.querySelector('.sidebar #page-link-verified');
                    uncontactedLink.click();
                },
            }
        },
        {
            id: 'connected',
            attachTo: {
                element: '.sidebar #page-link-connected',
                on: window.innerWidth <= 768 ? 'bottom' : 'right'
            },
            buttons: [
            {
                classes: 'shepherd-button-primary prev',
                text: '',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: '',
                type: 'next'
            }
            ],
            classes: 'arrow-left',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-connected"),
            text: [TranslationService.getTranslation("walkthrough-connected-body")],
            when: {
                show: () => {
                    const connectedLink = document.querySelector('.sidebar #page-link-connected');
                    connectedLink.click();
                },
            }
        },
        {
            id: 'profile',
            attachTo: {
                element: '.sidebar #page-link-profile',
                on: window.innerWidth <= 768 ? 'bottom' : 'right'
            },
            buttons: [
            {
                classes: 'shepherd-button-primary prev',
                text: '',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: '',
                type: 'next'
            }
            ],
            classes: 'arrow-left',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-profile"),
            text: [TranslationService.getTranslation("walkthrough-profile-body")],
            when: {
                show: () => {
                    const profileLink = document.querySelector('.sidebar #page-link-profile');
                    profileLink.click();
                },
            }
        },
        {
            id: 'help',
            attachTo: {
                element: '.sidebar #page-link-help',
                on: window.innerWidth <= 768 ? 'bottom' : 'right'
            },
            buttons: [
            {
                classes: 'shepherd-button-primary prev',
                text: '',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: '',
                type: 'next'
            }
            ],
            classes: 'arrow-left',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-help"),
            text: [TranslationService.getTranslation("walkthrough-help-body")],
            when: {
                show: () => {
                    const helpLink = document.querySelector('.sidebar #page-link-help');
                    helpLink.click();
                },
            }
        },
        {
            id: 'messages',
            attachTo: { element: '#header-message-icon', on: window.innerWidth <= 768 ? 'bottom' : 'left' },
            buttons: [
            {
                classes: 'shepherd-button-primary prev',
                text: '',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: '',
                type: 'next'
            }
            ],
            classes: 'arrow-right',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-messages"),
            text: [TranslationService.getTranslation("walkthrough-messages-body")],
            when: {
                show: () => {
                    if (window.innerWidth <= 768) {
                        const openMenuButton = document.querySelector('.page-links .menu-icon.active');
                        openMenuButton && openMenuButton.click();
                    }
                },
                hide: () => {
                    if (window.innerWidth <= 768) {
                        const closedMenuButton = document.querySelector('.page-links .menu-icon:not(.active)');
                        closedMenuButton && closedMenuButton.click();
                    }
                }
            }
        },
        {
            id: 'theme-switcher',
            attachTo: { element: '.theme-switch-wrapper', on: window.innerWidth <= 768 ? 'top' : 'right' },
            buttons: [
            {
                classes: 'shepherd-button-primary prev',
                text: '',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary shepherd-tour-end next',
                text: '',
                type: 'next'
            }
            ],
            classes: 'arrow-left',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-theme-switcher"),
            text: [TranslationService.getTranslation("walkthrough-theme-switcher-body")],
            when: {
                show: () => {
                    const profileLink = document.querySelector('.sidebar #page-link-profile');
                    profileLink.click();
                    if (window.innerWidth <= 768) {
                        const closedMenuButton = document.querySelector('.page-links .menu-icon:not(.active)');
                        closedMenuButton && closedMenuButton.click();
                    }
                },
            }
        },
        {
            id: 'agreements',
            attachTo: { element: '#checkbox-agreements', on: 'top' },
            buttons: [
            {
                classes: 'shepherd-button-primary prev',
                text: '',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: '',
                type: 'next'
            }
            ],
            classes: 'arrow-left',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
                enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-agreements"),
            text: [TranslationService.getTranslation("walkthrough-agreements-body")],
            when: {
                show: () => {
                    const profileLink = document.querySelector('.sidebar #page-link-profile');
                    profileLink.click();
                    if (window.innerWidth <= 768) {
                        const openMenuButton = document.querySelector('.page-links .menu-icon.active');
                        openMenuButton && openMenuButton.click();
                    }
                },
            }
        },
        {
            id: 'cta',
            buttons: [
            {
                classes: 'shepherd-button-primary prev',
                text: '',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: TranslationService.getTranslation("im-ready"),
                type: 'next'
            }
            ],
            classes: 'final-slide cta',
            highlightClass: 'highlight',
            scrollTo: false,
            cancelIcon: {
            enabled: false,
            },
            title: TranslationService.getTranslation("walkthrough-make-disciples"),
            text: 'Then Jesus came to them and said, "All authority in heaven and on earth has been given to me. Therefore go and make disciples of all nations..."'
        },
    ];
}
