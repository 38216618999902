import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import {
    AccordionContainer,
    AccordionList,
    AccordionRow,
    AccordionHeader,
    AccordionBody,
    DropdownArrow
} from './accordion-styles';

export default function Accordion({ options }) {
    const [openRow, setOpenRow] = useState(null);

    const toggleRow = index => () => {
        if (openRow === index) {
            setOpenRow(null);
        } else {
            setOpenRow(index);
        }
    };

    return (
            <AccordionContainer>
                {(
                    <AccordionList>
                        {options.map((option, index) => (
                            <AccordionRow isExpanded={openRow === index} key={Math.random()}>
                                <AccordionHeader isExpanded={openRow === index} onClick={toggleRow(index)}>
                                    <FontAwesomeIcon icon={faQuestionCircle} size="sm" className="icon" />
                                    <p>{option.label}</p>
                                    <DropdownArrow isExpanded={openRow === index}></DropdownArrow>
                                </AccordionHeader>
                                <AccordionBody isExpanded={openRow === index}>
                                    <p>{option.body}</p>
                                </AccordionBody>
                            </AccordionRow>
                        ))}
                    </AccordionList>
                )}
            </AccordionContainer>
    );
}