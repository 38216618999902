import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import APIService from "../services/api-service";
import WebsocketService from "../services/websocket-service";
import TranslationService from "../services/translation-service";
import getWalkthroughSteps from "../utilities/get-walkthrough-steps";
import { useDocumentTitle } from "../hooks";

const hostAdminContext = createContext();
const HostContext = (props) => (
  <hostAdminContext.Provider value={useHostAdminContext()}>
    {props.children}
  </hostAdminContext.Provider>
);
export const useHostAdmin = () => useContext(hostAdminContext);

const useHostAdminContext = () => {
  const [host, setHost] = useState();
  const [seekers, setSeekers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(0);
  const [languageIndex, setStateLanguageIndex] = useState();
  const [walkthroughSteps, setWalkthroughSteps] = useState([]);
  const initialTitle = "Unite Life - Host Admin";
  let currentTitle = initialTitle;
  const [title, setTitle] = useDocumentTitle(currentTitle);
  const history = useHistory();

  useEffect(() => {
    loadHostData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    showNumberOfUnreadMessagesInTitle();
    //eslint-disable-next-line
  }, [numberOfUnreadMessages]);

  const forceReloadData = () => {
    loadHostData();
  };

  const showNumberOfUnreadMessagesInTitle = () => {
    if (numberOfUnreadMessages > 0) {
      currentTitle = `(${numberOfUnreadMessages}) Unread Messages`;
    } else {
      resetTitle();
    }
  
    setTitle(currentTitle);
  };

  const resetTitle = () => {
    currentTitle = initialTitle;
    setTitle(currentTitle);
  };

  /**
   * Loads host data. If unsuccessful, the user is redirected to the login page
   */
  const loadHostData = async () => {
    if (!localStorage.getItem("token")) history.push('/');

    let hostResponse = await APIService.getHost();
    if (hostResponse) {
      const hostUser = hostResponse.user;
      const host = {
        ...hostResponse,
        ...hostUser,
        agreements: typeof hostResponse.agreements === 'string' ?
          JSON.parse(hostResponse.agreements) :
          hostResponse.agreements
      }
      delete host.user;

      const unreadMessages = await APIService.getHostUnreadMessages();

      let languageIndex = TranslationService.getLanguageIndex(
        host.primary_language
      );
      TranslationService.loadTranslations(host.primary_language);
      const translatedSteps = getWalkthroughSteps();

      document
        .getElementById("root")
        .setAttribute("data-theme-region", host.country || "us");
      /*if (host.country === "az" || host.country === "uz") {
        document.getElementById("root").setAttribute("color-scheme", "umid");
      }*/

      setHost(host);
      const unreadCount = unreadMessages.length;
      setNumberOfUnreadMessages(unreadCount);
      setUnreadMessages(unreadMessages);
      setStateLanguageIndex(languageIndex);
      setWalkthroughSteps(translatedSteps);
      setLoading(false);
    } else {
      history.push('/');
    }
  };

  /**
   * Authorizes a host.
   * @param {String} email
   * @param {String} password
   * @returns {Promise<Object>} Object containing the authorized host object and an access token
   */
   const login = async (email, password) => {
    try {
      const { accessToken } = await APIService.login(email, password);
      localStorage.setItem("token", accessToken);
      loadHostData();
       history.push('/dashboard');
    } catch (err) {
      console.error(err);
      return false;
    }
  };
  const logout = () => {
    WebsocketService.disconnectSocket();
    localStorage.removeItem("token");
    history.push('/');
  };

  /**
   * Resets a host's password.
   * @param {String} email
   * @param {String} password
   * @returns {Promise<Object>} Object containing the authorized host object and an access token
   */
   const resetPassword = async (email, oldPassword, newPassword) => {
    try {
      const { accessToken } = await APIService.resetPassword(email, oldPassword, newPassword);
      localStorage.setItem("token", accessToken);
      loadHostData();
       history.push('/dashboard');
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  /**
   * Updates the current language index
   * @param {Number} index The new language index
   */
  const setLanguageIndex = async (index) => {
    let { code } = TranslationService.languages[index];
    TranslationService.loadTranslations(code);
    setStateLanguageIndex(index);

    await APIService.updateHost({ primary_language: code });
  };

  /**
   * Update host profile
   * @param {*} profile
   */
  const updateHostProfile = async (profile) => {
    return await APIService.updateHost(profile);
  };

  /**
   * Marks a seeker as "connected"
   * @param {String} seekerUuid The unique uuid of the seeker
   */
  const connectSeeker = async (seekerUuid) => {
      await APIService.autoConnectSeeker({
        seekerUuid
      });
    let index = seekers.findIndex(
      ({ uuid }) => uuid === seekerUuid
    );
    seekers[index].status = 'connected';
    setSeekers([...seekers]);
  };

  /**
   * Marks a conversation as "blocked"
   * @param {String} seekerUuid The seeker's uuid
   */
  const hideSeekerConversation = async (seekerUuid) => {
    const conversation = await APIService.getConversation(seekerUuid);
    await APIService.updateConversation(conversation.uuid, { status: 'blocked' });
    loadHostData();
  };

  /**
   * Marks all messages for a seeker as read
   * @param {String} seekerUuid The uuid of the seeker
   */
  const markMessagesAsRead = async (conversationUuid) => {
    await APIService.markAllConversationMessagesAsRead(conversationUuid);
    forceReloadData();
  };

  /**
   * Updates the last message of a given seeker in local state only - so that the seeker list UI shows the new message instantly
   * @param {String} seekerUuid The uuid of the seeker
   * @param {String} message The last message sent
   * @param {String} direction The message direction i.e. host to seeker or seeker to host
   */
  const updateSeekerLastMessage = (seekerUuid, message, direction) => {
    let index = seekers.findIndex(
      ({ uuid }) => uuid === seekerUuid
    );
    seekers[index].lastMessage = {
      text: message.text,
      direction,
      timestamp: new Date().toISOString()
    };
    const lastSeekerMessage = seekers.splice(index, 1);
    seekers.unshift(...lastSeekerMessage);
    setSeekers([...seekers]);
  };

  const formatSeekers = (seekers) => {
    return seekers.map(seeker => {
      const seekerUser = seeker.user;
      return {
        ...seeker,
        ...seekerUser
      }
    });
  };

  return {
    loading,
    host,
    formatSeekers,
    login,
    logout,
    resetPassword,
    loadHostData,
    seekers,
    languageIndex,
    walkthroughSteps,
    setLanguageIndex,
    updateHostProfile,
    unreadMessages,
    setUnreadMessages,
    numberOfUnreadMessages,
    setNumberOfUnreadMessages,
    connectSeeker,
    markMessagesAsRead,
    updateSeekerLastMessage,
    hideSeekerConversation,
  };
};

export default HostContext;
