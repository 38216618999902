import styled, {css, keyframes} from "styled-components";

export const AccordionContainer = styled("div")`
  position: relative;
  display: inline-block;
  margin: 0 0.25em;
`;

export const AccordionList = styled("ul")`
  padding: 0;
  margin: 0;
  background: var(--ui-gray-dark);
  border: 1px solid var(--ui-gray-lighter);
  box-sizing: border-box;
  color: var(--text-default);
  font-weight: 500;
  transform: translate(0, -15px);
  width: 100%;
  z-index: 100;
  border-radius: var(--border-radius-md);
`;

export const AccordionRow = styled("li")`
  list-style: none;
  margin-bottom: 0;
  &:first-of-type button {
    border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
  }
  &:last-of-type button {
    margin-bottom: 0;
    border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
  }
`;

export const AccordionHeader = styled("button")`
  display: block;
  position: relative;
  margin: 0;
  width: 100%;
  padding: 1.5rem 1rem;
  text-align: left;
  color: var(--text-default);
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  ${props => props.isExpanded && css`
    font-weight: bold;
    background: rgba(0, 0, 0, 0.3);
  `};
`;

const ExpandRow = keyframes`
    0% { 
        max-height: 0;
        padding: 0 2rem;
    }
    100% {
        max-height: initial;
        display: block;
        padding: 2rem;
        opacity: 1;
    }
`;
export const AccordionBody = styled("div")`
  max-height: 0;
  padding: 0 2rem;
  opacity: 0;
  transition: all 0.3s;
  ${props => props.isExpanded && css`
    background: rgba(0,0,0,0.1);
    animation: ${ExpandRow} 0.3s forwards;
  `};
`;


export const DropdownArrow = styled("div")`
    position: absolute;
    top: calc(50% - 5px);
    right: 2rem;
    border-bottom: 3px solid var(--text-default);
    border-right: 3px solid var(--text-default);
    width: 14px;
    height: 14px;
    transform: rotate(-135deg);
    transition: transform 0.2s ease-out;
    ${props => props.isExpanded && css`
        top: calc(50% - 10px);
        transform: rotate(45deg);
  `};
  @media (max-width: 768px) {
    right: 1rem;
    width: 10px;
    height: 10px;
  }
`;
