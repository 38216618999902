import styled, { css } from "styled-components";

export const PageHeaderBarWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PageHeaderBarTitle = styled("h1")``;

export const MessageIconWrapper = styled("span")`
  position: relative;

  &:hover {
    cursor: pointer;
    color: var(--text-link);
  }
`;

export const HasUnreadMessagesIcon = styled("span")`
  position: absolute;
  top: -9px;
  left: -6px;
  color: orange;
  ${(props) =>
    props.messageCount === 0 &&
    css`
      display: none;
    `};
`;

export const MessagesOverlay = styled("div")`
  position: absolute;
  top: 1.5rem;
  left: -290px;
  width: 300px;
  background: var(--ui-gray-lighter);
  z-index: 10000;
  border-radius: var(--border-radius-md);
  background-color: hsla(0, 0, 0, 0);
  ${(props) =>
    props.showMessages === false &&
    css`
      display: none;
    `};
`;

//export const PageHeaderBarMessageIconHasUnreadMessages =
