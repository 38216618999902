import React, { useState } from "react";
import {
    DropDownContainer,
    DropDownHeader,
    DropDownListContainer,
    DropDownList,
    ListItem
} from './dropdown-styles';

export default function Dropdown({ value, options, onChange }) {
    const [isOpen, setIsOpen] = useState(false);
    if (!value) {
        value = options[0];
    }
    const [selectedOption, setSelectedOption] = useState(value);
    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
        onChange(value);
    };

    return (
            <DropDownContainer>
                <DropDownHeader onClick={toggling}>
                    {selectedOption.label}
                </DropDownHeader>
                {isOpen && (
                    <DropDownListContainer>
                        <DropDownList>
                            {options.map(option => (
                                <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                                    {option.label}
                                </ListItem>
                            ))}
                        </DropDownList>
                    </DropDownListContainer>
                )}
            </DropDownContainer>
    );
}