class APIService {
  static API = process.env.REACT_APP_API_URL;
  // static API = "https://staging.udevapi.com/host-admin/api"

  static apiEndpoints = {
    login: "/login",
    host: "/host",
    hostSeekers: "/host/seekers",
    hostUnreadMessages: "/host/unread-messages",
    hostImpactMetrics: "/host/metrics",
    singleSeeker: "/host/seeker",
    autoConnectSeeker: "/host/connect-seeker",
    conversation: "/messaging/conversation",
    message: "/messaging/message",
    markAllRead: "/messaging/mark-conversation-messages-read"
  };

  static async login(email, password) {
    return this.makeRequest("POST", this.apiEndpoints.login, { email, password });
  }

  static async resetPassword(email, oldPassword, newPassword) {
    return this.makeRequest("POST", `/password`, { email, oldPassword, newPassword });
  }

  static async getHost() {
    return this.makeAuthenticatedRequest("GET", this.apiEndpoints.host, null, localStorage.getItem("token"))
  }

  static async updateHost(data) {
    return this.makeAuthenticatedRequest("PATCH", this.apiEndpoints.host, data, localStorage.getItem("token"));
  }

  static async getHostSeekers(connectionStatus, page) {
    return this.makeAuthenticatedRequest("GET", this.apiEndpoints.hostSeekers + `?connectionStatus=${connectionStatus}&page=${page}`, null, localStorage.getItem("token"));
  }

  static async getSingleSeeker(uuid) {
    return this.makeAuthenticatedRequest("GET", this.apiEndpoints.singleSeeker + `?uuid=${uuid}`, null, localStorage.getItem("token"));
  }

  static async getHostUnreadMessages() {
    return this.makeAuthenticatedRequest("GET", this.apiEndpoints.hostUnreadMessages, null, localStorage.getItem("token"))
  }

  static async getHostImpactMetrics(dateRange) {
    return this.makeAuthenticatedRequest("POST", this.apiEndpoints.hostImpactMetrics, {dateRange}, localStorage.getItem("token"));
  }

  static async autoConnectSeeker(data) {
    return this.makeAuthenticatedRequest("POST", this.apiEndpoints.autoConnectSeeker, data, localStorage.getItem("token"));
  }

  static async getConversation(seekerUuid) {
    return this.makeAuthenticatedRequest("GET", `${this.apiEndpoints.conversation}?seekerUuid=${seekerUuid}`,
      null,
      localStorage.getItem("token"))
  }

  static async updateConversation(conversationUuid, data) {
    return this.makeAuthenticatedRequest("PATCH", `${this.apiEndpoints.conversation}/${conversationUuid}`,
      data,
      localStorage.getItem("token"))
  }

  static async markAllConversationMessagesAsRead(conversationUuid) {
    return this.makeAuthenticatedRequest("PATCH", `${this.apiEndpoints.markAllRead}/${conversationUuid}`,
      {},
      localStorage.getItem("token"))
  }

  static async sendNewMessage(seekerUuid, message) {
    return this.makeAuthenticatedRequest("POST", this.apiEndpoints.message, {
      seekerUuid,
      message,
    },
    localStorage.getItem("token"))
  }

  static async updateMessage(messageUuid, data) {
    return this.makeAuthenticatedRequest("PATCH", `${this.apiEndpoints.message}/${messageUuid}`,
      data,
      localStorage.getItem("token"))
  }


  /**
   * Makes a request to the API
   * @param {String} method The request method
   * @param {String} path The api path (without url)
   * @param {Object} params Parameters to include with the request
   * @returns {Object} The API's response
   */
  static async makeRequest(method, path, params = null) {
    return new Promise((resolve, reject) => {
      try {
        if (method === "GET") {
          let endpoint = this.API + path;
          if (params) {
            let queryString = "?";
            Object.keys(params).forEach((key) => {
              queryString += `${key}=${params[key]}&`;
            });
            endpoint = (endpoint + queryString).slice(0, -1);
          }
          fetch(endpoint, {
            // headers: { Authorization: `Bearer ${this.token}` },
          }).then((response) => {
            if (response.status === 200) {
              response.json().then((result) => {
                resolve(result);
              });
            } else {
              reject();
            }
          });
        } else {
          fetch(this.API + path, {
            method,
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(params),
          }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((result) => {
                resolve(result);
              });
            } else {
              reject();
            }
          });
        }
      } catch (error) {
        resolve({ error });
      }
    });
  }

  /**
   * Makes an authenticated request to the API
   * @param {String} method The request method
   * @param {String} path The api path (without url)
   * @param {Object} params Parameters to include with the request
   * @returns {Object} The API's response
   */
   static async makeAuthenticatedRequest(method, path, params = null, token) {
    return new Promise((resolve, reject) => {
      try {
        if (method === "GET") {
          let endpoint = this.API + path;
          if (params) {
            let queryString = "?";
            Object.keys(params).forEach((key) => {
              queryString += `${key}=${params[key]}&`;
            });
            endpoint = (endpoint + queryString).slice(0, -1);
          }
          fetch(endpoint, {
            headers: { Authorization: `Bearer ${token}` },
          }).then((response) => {
            if (response.status === 200) {
              response.json().then((result) => {
                resolve(result);
              });
            } else {
              reject();
            }
          });
        } else {
          fetch(this.API + path, {
            method,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(params),
          }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((result) => {
                resolve(result);
              });
            } else {
              reject();
            }
          });
        }
      } catch (error) {
        resolve({ error });
      }
    });
  }
}

export default APIService;
