import React, { useState } from "react";
import moment from "moment";
import { useHostAdmin } from "../../context/host-admin-context";
import { useMessaging } from "../../context/messaging/messaging-context";
import TranslationService from "../../services/translation-service";
import Spinner from "../spinner";
import SeekerProfileInfoRow from "./seeker-profile-info-row";



const SeekerProfileInfo = ({ isMobileActive }) => {
  const { connectSeeker } = useHostAdmin();
  const { seeker, loading } = useMessaging();
  const seekerProfileInfoRows = [
  {
    title: TranslationService.getTranslation("gi-created"),
    value: "created_at",
    format: (val) => moment(val).format("MMM DD, YYYY"),
  },
  {
    title: TranslationService.getTranslation("gi-location"),
    value: "city",
  },
  {
    title: TranslationService.getTranslation("gi-using"),
    value: "preferred_contact_method",
  },
  {
    title: TranslationService.getTranslation("phone"),
    value: "phone",
    requiresConnection: true,
  },
  {
    title: TranslationService.getTranslation("email"),
    value: "email",
    requiresConnection: true,
  },
  {
    title: TranslationService.getTranslation("source"),
    value: "origin",
    requiresConnection: true,
  },
];
  const isConnected = seeker?.status === "connected";
  const [isVisible, setIsVisible] = useState(isConnected);

  if (loading || !seeker) {
    return <Spinner alt />;
  }

  return (
    <ul className={`seeker-profile-info`}>
      {seekerProfileInfoRows.map((infoRow) => (
        <SeekerProfileInfoRow
          key={infoRow.title}
          {...infoRow}
          value={seeker[infoRow.value]}
          // hasConnection={isConnected}
          isVisible={isVisible}
        />
      ))}
      {!isVisible && (
        <li><button
          className="click-to-connect"
          onClick={() => setIsVisible(true)}
        >
          {TranslationService.translations["show-contact-information"]}
        </button></li>
      )}
    </ul>
  );
};

export default SeekerProfileInfo;
