import moment from "moment";
import React, { createContext, useContext, useEffect } from "react";
import { useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import APIService from "../../services/api-service";
import TranslationService from "../../services/translation-service";
import WebsocketService from "../../services/websocket-service";
import playNotification from "../../utilities/play-notification";
import { useHostAdmin } from "../host-admin-context";
import { useSeekers } from "../../hooks";
import MessagingReducer from "./messaging-reducer";
import actions from "./messaging-actions";
import { useNotifications } from "../notification-context";

const messagingContext = createContext();
const MessagingContext = (props) => (
  <messagingContext.Provider value={useMessagingContext()}>
    {props.children}
  </messagingContext.Provider>
);
export const useMessaging = () => useContext(messagingContext);

const initialState = {
  seeker: null,
  conversation: [],
  loading: true,
};

const useMessagingContext = () => {
  const [{ seeker, conversation, loading }, dispatch] = useReducer(
    MessagingReducer,
    initialState
  );

  const { pathname } = useLocation();
  const history = useHistory();
  const { pushNotification } = useNotifications();

  const {
    host,
    connectSeeker,
    unreadMessages,
    setUnreadMessages,
    numberOfUnreadMessages,
    setNumberOfUnreadMessages,
    seekers,
    loading: loadingHost,
    markMessagesAsRead,
    formatSeekers
  } = useHostAdmin();

  const { refetchSeekers } = useSeekers();

  /**
   * Initialize the WebsocketService when a host is logged in
   */
  useEffect(() => {
    if (host?.uuid) {
      WebsocketService.initWebSocketClient(host.uuid, {
        message: (data) => newMessageCallback(data),
      });
    }
    //eslint-disable-next-line
  }, [host]);

  /**
   * Memoize the message callback and update when dependencies change
   */
  useEffect(() => {
    WebsocketService.setHandler("message", (data) => newMessageCallback(data));
    //eslint-disable-next-line
  }, [seeker, unreadMessages]);

  /**
   * Loads a conversation when a seeker page is open, otherwise the state is cleared
   */
   useEffect(() => {
    const getConversation = async () => {
      if (!loadingHost && seekers && pathname.includes("/seeker/")) {
        let split = pathname.split("/");
        let seekerUuid = split[split.length - 1];
  
        let newSeeker = seekers.find(
          ({ uuid }) => uuid === seekerUuid
        );
        
        if (newSeeker === undefined) {
          // user reloads the seeker page but current seeker is not in first page of fetched seekers
          const seeker = await APIService.getSingleSeeker(seekerUuid);
          const formattedSeeker = formatSeekers([seeker])[0];
          loadConversation(formattedSeeker);
        } else if (seeker?.uuid !== seekerUuid) {
          loadConversation(newSeeker);
        }
      } else {
        dispatch({ type: actions.clear });
      }
    };
  
    getConversation();
    //eslint-disable-next-line
  }, [loadingHost, seekers, pathname]);
  

  /**
   * Finds a message by its uuid and marks it as read
   * @param {String} messageUuid 
   */
  const updateMessageAsRead = async (messageUuid) => {
    await APIService.updateMessage(messageUuid, { status: 'read' });
  };

  /**
   * Finds a message by its uuid and marks it as delivered
   * @param {String} messageUuid 
   */
   const updateMessageAsDelivered = async (messageUuid) => {
    await APIService.updateMessage(messageUuid, { status: 'delivered' });
  };

  /**
   * Loads the conversation between the current host and seeker
   * @param {Object} seeker The seeker to load a conversation for
   */
  const loadConversation = async (seeker) => {
    if (host && seeker) {
      let conversation = await APIService.getConversation(
        seeker.uuid
      );

      // These are the messages that have been sent while the host has been off the app
      // This step verifies that they have indeed been delivered and read
      const messagesSentToBeMarkedAsDelivered = conversation.messages.filter((msg) => msg.status === 'sent')
        .reduce((total, msg) => [...total, msg.uuid], []);

      if (messagesSentToBeMarkedAsDelivered) {
        for (const uuid of messagesSentToBeMarkedAsDelivered) {
          await updateMessageAsRead(uuid);
        }
      }

      const conversationHasUnreadMessages = conversation.messages.some((msg) => (
        msg.status === 'delivered' &&
        msg.direction === 'seeker_to_host'
      ));
      if (conversationHasUnreadMessages) await markMessagesAsRead(conversation.uuid);

      dispatch({ type: actions.load, payload: { seeker, conversation } });
    } else {
      dispatch({
        type: actions.load,
        payload: { seeker: null, conversation: [] },
      });
    }
  };

  /**
   * Handles a new incoming message by:
   * - If the current seeker is open, adding it to the current conversation
   * - Otherwise, it pushes a notification and updates the appropriate fields
   *
   * @param {Object} messageData WebSocket message data
   */
  const newMessageCallback = (messageData) => {
    const { seekerId, seekerName, message } = messageData;

    refetchSeekers("uncontacted");
    refetchSeekers("already_connected");
    refetchSeekers("confirmed_connected");

    const seekerIsOpen = seekerId === seeker?.uuid;

    if (seekerIsOpen) {
      dispatch({
        type: actions.message,
        payload: { text: message.text, sentBySeeker: true, direction: "seeker_to_host" },
      });
      playNotification();
      updateMessageAsRead(message.uuid);
    } else {
      unreadMessages.push(message);
      pushNotification(
        `${TranslationService.translations["toast-message"]} ${seekerName}`,
        () => history.push(`/seeker/${seekerId}`)
      );
      updateMessageAsDelivered(message.uuid);
      setUnreadMessages([...unreadMessages]);
      setNumberOfUnreadMessages(numberOfUnreadMessages + 1);
    }
  };

  /**
   * Sends a message to the seeker
   * - If the message isn't delivered, the state is updated appropriately
   * - Otherwise, the seeker is considered connected
   * @param {String} message Message to send
   */
  const sendMessage = async (message) => {
    dispatch({
      type: actions.message,
      payload: {
        text: message,
        sentBySeeker: false,
        delivered: true,
        channel: "",
        timestamp: moment().format(),
      },
    });
    refetchSeekers("uncontacted");
    refetchSeekers("already_connected");
    refetchSeekers("confirmed_connected");

    let result = await APIService.sendNewMessage(
      seeker.uuid,
      message
    );

    if (!result.length) {
      dispatch({ type: actions.notDelivered });
    } else if (seeker.status !== 'connected') {
      connectSeeker(seeker.uuid);
    }
  };

  return { seeker, loading, conversation, sendMessage };
};

export default MessagingContext;
