import notification from "../media/notification.mp3";
//Sound from https://notificationsounds.com/notification-sounds/light-562

function playNotification() {
  let audio = new Audio(notification);
  try {
    audio.play();
  } catch {
    //do nothing
  }
}

export default playNotification;
