const SeekerProfileInfoRow = ({
  title,
  value,
  // hasConnection,
  isVisible,
  requiresConnection = false,
  format = null,
}) => {
  if (requiresConnection && /*!hasConnection*/!isVisible) return null;
  if (!value) return null;
  return (
    <li>
      <strong>{title}</strong> {format ? format(value) : value}
    </li>
  );
};

export default SeekerProfileInfoRow;
