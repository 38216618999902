import React from "react";
import "./seeker-list.scss";
import Spinner from "../../components/spinner";
import TranslationService from "../../services/translation-service";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { useHostAdmin } from "../../context/host-admin-context";
import { useSeekers } from "../../hooks";
import { Card, Message, PageHeaderBar } from "../../components/v2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
const listFilters = [
  {
    value: "uncontacted",
    getLabel: () => TranslationService.getTranslation("hq-unverified"),
  },
  {
    value: "already_connected",
    getLabel: () => TranslationService.getTranslation("hq-uncontacted"),
  },
  {
    value: "confirmed_connected",
    getLabel: () => TranslationService.getTranslation("hq-connected"),
  },
];

const SeekerList = ({ filter }) => {
  const currentFilter = listFilters.find(({ value }) => value === filter);
  const breadcrumbPathName = currentFilter.getLabel();
  const { seekers, fetchMoreSeekers, isLoading, isFetchingMore, hasNextPage } = useSeekers(filter);

  const getNextPage = () => {
    fetchMoreSeekers();
  };

  return (
    <div className={"page"}>
      {!isLoading && (
        <section className="page__body">
          <section className="page__title">
            <PageHeaderBar breadcrumb={breadcrumbPathName} />
          </section>
          <section className="page__section grid--1-1">
            {seekers.map((seeker, i) => (
              <section
                className="page__subsection"
                key={i + seeker.uuid}
              >
                <Card className="">
                  <Card.Heading>
                    <Card.Title>
                      <div className={"title-flex"}>
                        {seeker.first_name} {seeker.last_name}
                      </div>
                    </Card.Title>
                    <Card.ViewLink
                      to={`/seeker/${seeker.uuid}`}
                      className="text-sm bold"
                    >
                      {TranslationService.translations["view-profile"]}
                      <Card.IconWrapper>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Card.IconWrapper>
                    </Card.ViewLink>
                  </Card.Heading>
                  <Card.Body>
                    <h3>{TranslationService.translations["gi-location"]}</h3>
                    <hr />
                    <ul>
                      <li>{seeker?.city?.toUpperCase()}</li>
                      <li>{seeker?.country?.toUpperCase()}</li>
                    </ul>
                    <div
                      className={"last-message d-flex justify-content-between"}
                    >
                      <h3>
                        {TranslationService.translations["gi-last-message"]}
                      </h3>
                      <Card.ViewLink
                        to={`/seeker/${seeker.uuid}`}
                        className="text-sm bold"
                      >
                        {TranslationService.translations["chat-title"]}
                        <Card.IconWrapper>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </Card.IconWrapper>
                      </Card.ViewLink>
                    </div>

                    <hr />
                    <div className={"message-container"}>
                      <Message
                        message={seeker.lastMessage}
                        contactMethod={seeker.preferred_contact_method}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </section>
            ))}
            {!isLoading && seekers.length === 0 && (
              <p className="empty">
                {TranslationService.translations["hq-no-guests"]}
              </p>
            )}
          </section>
          {(!isFetchingMore && hasNextPage) && (
              <button className="load-more-btn btn btn-primary-outline" onClick={getNextPage}>Load More</button>
          )}
          {isFetchingMore && (
            <Spinner text={"Loading.."} />
          )}
        </section>
      )}
      {isLoading && (
        <div className="seeker-info-row--loading">
          <Spinner alt text="Loading..." />
        </div>
      )}
    </div>
  );
};

export default withRouter(SeekerList);
